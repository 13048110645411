//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'ContractDetail',
	props: {
		contractId: {
			type: String,
			default: '0-000001'
		},
		contract: []
	},
	data() {
		return {
			isLoading: false,
		}
	},
	methods: {
		goBack() {
			this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/step1'})
		}
	},
}
