import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchMoneyTransferList(params){
    return request({
        url: baseURL + '/moneyTransfer/list',
        method: 'GET',
        params: params
    })
}

export function fetchMoneyTransferDetail(param) {
    return request({
        url: baseURL + '/moneyTransfer/detail',
        method: 'GET',
        params: param
    })
}

export function moneyTransferSave(data) {
    return request({
        url: baseURL + '/moneyTransfer/save',
        method: 'POST',
        data
    })
}