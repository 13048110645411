//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContractDetail from "@/views/components/ContractDetail";
import { fetchMoneyTransferDetail, moneyTransferSave } from "@/api/moneyTransfer"
import { Message } from "element-ui";

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
export default {
  name: 'TransferLoanDetail',
  components: {ContractDetail},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.usersOpened = from.fullPath.includes('CheckDocument')
    })
  },
  data() {
    var validateImage = (rule, value, callback) => {
      if (this.formData.images.length === 0 && this.temp.attachments.length === 0) {
        callback(new Error('กรุณาเพิ่มรูปภาพ'));
      } else {
        callback();
      }
    };
    return {
      items: [],
      status: [{value: 2, label: 'โอนเรียบร้อย'}, {value: 5, label: 'ขอเบิกก่อน'}],
      isLoading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      temp: {
        attachments: []
      },
      contractParam: {
        contractId: this.$route.params.id
      },
      formData: {
        contractId: '',
        statusId: '',
        transferDate: '',
        stepdImageId: '',
        images: [],
        imagesDel: []
      },
      formRules: {
        statusId: [
          { required: true, message: 'กรุณาเลือกสถานะของสัญญา', trigger: 'change' }
        ],
        transferDate: [
          { required: true, message: 'กรุณาเลือกวันที่', trigger: 'change' }
        ],
        attachments: [
          { required: true, validator: validateImage, trigger: 'blur' }
        ],
      },
    }
  },
  computed: {
    contractDetail() {
      return this.items;
    },
  },
  async mounted() {
    this.$store.state.loading = true
    try {
      await this.getMoneyTransferDetail();
    } finally {
      this.$store.state.loading = false
    }
  },
  methods: {
    getMoneyTransferDetail(){
      console.log('contractParam : ', this.contractParam)
      fetchMoneyTransferDetail(this.contractParam).then(res =>{
        this.items = []
        const check = res.header;
        const data = res.body;
        if (check.error === 'N') {
          this.formData.contractId = data[0].contract_id;
          this.formData.statusId = data[0].status_id;
          this.formData.transferDate = data[0].transfer_date;
          this.formData.stepdImageId = data[0].stepd_image_id;
          this.items = data[0];
          this.temp.attachments = data[0].images;
        }
      }).catch(error => {
        Message({
          message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
          type: 'error',
          duration: 5 * 1000,
        })
        this.errorMsg = error
        console.log('error :', error)
      })
    },
    saveMoneyTransfer(){
      console.log('formData : ', this.formData);
      this.$refs['detailForm'].validate((valid) => {
        if (valid) {
          moneyTransferSave(this.formData).then(response => {
            const check = response.header
            const data = response.body
            if (check.error === 'N') {
              Message({
                message: 'บันทึกข้อมูลสำเร็จ',
                type: 'success',
                duration: 2 * 1000,
                onClose: () => {
                  this.goBack()
                }
              })
            }
          }).catch(error => {
            Message({
              message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
              type: 'error',
              duration: 2 * 1000,
            })
            this.errorMsg = error
            console.log(error)
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async beforeUpload(file) {
      const data = await toBase64(file)
      this.formData.images.push(data)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async handleRemove(file, fileList) {
      console.log(fileList)
      if (this.formData.images.length > 0) {
        for (let i = 0; i < this.formData.images.length; i++) {
          if (this.formData.images[i] === (await toBase64(file.raw))) {
            this.formData.images.splice(i, 1)
            i--
          }
        }
      } else if (Number(this.temp.attachments.length) > 0) {
        for (let index = 0; index < this.temp.attachments.length; index++) {
          if (this.temp.attachments[index].name === file.name) {
            this.formData.imagesDel.push(file.name)
          }
        }
      }
    },
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/step5/TransferLoan'})
    }
  },
}
